@use "../../styles/variables" as *;

.form {
  .tip {
    width: 438px;
    color: $brown-grey-three;
  }

  :global {
    .ant-form-item-label {
      width: 100%;
      text-align: left;
      > label {
        color: $dark-blue;
        font-weight: 500;
      }
    }
    .ant-form-item {
      width: 438px;
      margin-bottom: 0;
    }
    .ant-form-item-control {
      height: 47px;
    }
    .ant-btn {
      width: 180px;
    }
  }
}