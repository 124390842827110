@use "variables" as *;

.ant-steps {
  width: 400px;
  margin-top: 16px;
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: $white;
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
    color: $vivid-blue;
  }
  .ant-steps-item-process .ant-steps-item-icon {
    border: 2px solid $vivid-blue;
  }
  .ant-steps-item-wait {
    .ant-steps-item-icon {
      width: 18px;
      height: 18px;
      .ant-steps-icon {
        top: -4px;
      }
    }
  }
  .ant-steps-item-active {
    .ant-steps-item-icon {
      width: 18px;
      height: 18px;
      .ant-steps-icon {
        top: -5px;
      }
    }
  }
  .ant-steps-item-finish {
    .ant-steps-item-icon {
      background-color: $vivid-blue;
      width: 20px;
      height: 20px;
      .ant-steps-icon {
        top: -3px;
        color: $white;
      }
    }
  }
}

.ant-steps-small .ant-steps-item-title::after {
  top: 8px !important;
  left: 0;
  height: 3px;
}
.ant-steps-small .ant-steps-item-icon {
  margin: 0;
}
.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 0;
}

.ant-tooltip {
  line-height: 1.25;

  .ant-tooltip-inner {
    width: 202px;
    text-align: center;
  }
}

.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  margin-top: 460px;
}
