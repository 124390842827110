@use "../../styles/variables" as *;

.redeemedSuccessful {
  .text {
    width: 515px;
    font-size: 18px;
    font-weight: 500;
    color: $greyish-brown;
  }

  :global {
    .ant-btn {
      margin-top: 22px;
      width: 214px;
    }
  }
}