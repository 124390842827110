@use "../../styles/variables" as *;

.voucherRedemption {
  .codeInput {
    border-radius: 4px;
    width: 600px;
    margin-bottom: 5px;
    input {
      font-size: 16px;
      font-weight: bold;
    }
  }

  .tip {
    color: $brown-grey-three;
  }

  .continueBtn {
    width: 180px;
    margin-top: 16px;
  }

  .terms {
    text-align: justify;
    color: $brown-grey-three;
    width: 600px;
    height: 200px;
    border: 1px solid $brown-grey-four;
    border-radius: 4px;
    padding: 12px;
    line-height: 1.25;
  }
}
