@use "../../styles/variables" as *;

.redeemConfirmation {

  .form {
    background-color: $pale-grey-two;
    padding: 24px 40px 12px 40px;
    width: 328px;
    min-height: 210px;

    span {
      color: $greyish-brown;
      font-size: 16px;
      font-weight: 500;
    }
  }

  :global {
    .ant-btn {
      width: 180px;
    }

    .ant-form-item-label {
      width: 100%;
      text-align: left;
      > label {
        height: 15px;
        font-size: 14px;
        color: $greyish-brown;
      }
    }
    .ant-form-item {
      margin-bottom: 15px;
    }
    .ant-form-item-control-input {
      min-height: 25px;
    }
  }
}