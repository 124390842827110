// Color system
$white: #fff;
$black: #000;
$greyish-brown: #474545;
$pale-grey: #f3f4ff;
$dark-blue: #1c2154;
$vivid-blue: #1644f9;
$brown-grey: #858585;
$brown-grey-two: #7f7f7f;
$brown-grey-three: #9c9c9c;
$brown-grey-four: #cccccc;
$brown-grey-five: #e3e3e8;
$brown-grey-six: #f8f8f8;
$brown-grey-seven: #dadee7;
$background: #f4f4f4;
$pale-grey-two: #f2f3fa;
$pale-blue: #f3f5fe;
$border-grey: #e5e5e5;
$disabled-color: #bfbfbf;
$emerald: #00a854;

// Typography
$font-size-root: null !default;
$font-size-base: 1rem !default;
$font-size-sm: $font-size-base * 0.875 !default;
$font-size-lg: $font-size-base * 1.25 !default;
