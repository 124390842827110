@use "../../styles/variables" as *;
@use "../../styles/mixins";

.footer {
  width: 567px;
  padding: 0;
  text-align: center;
  color: $white;
  background: none;
  margin-left: 98px;
  a {
    font-size:   14px;
    color: $white;
    text-decoration: none;
  }
}

.copyright {
  font-size: 12px;
  margin-top: 10px;
}

.links {
  margin: 0;

  li {
    display: inline-block;
  }

  span {
    margin-left: 4px;
    margin-right: 4px;
  }

  .bold {
    font-weight: bold;
    margin: 0;
  }
}