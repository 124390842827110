@use "../styles/variables" as *;

.mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,.45);
}

.layout {
  width: 764px;
  margin:0 auto;
  height: 100%;
  display: table;
}

.container {
  height: calc(100vh - 80px);
  min-height: 700px;
}

.content {
  width: 764px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: $white;
  padding: 32px 82px;
}
