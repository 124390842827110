@use "../../styles/variables";

.container {
  display: flex;
  width: 438px;
  flex-direction: column;
  align-items: center;

  > :not(:last-child) {
    margin-bottom: 16px;
  }

  > h4 {
    color: variables.$dark-blue;
  }

  .subtitle {
    color: variables.$brown-grey-two
  }
}

.content {
  align-self: stretch;
}
