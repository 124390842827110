@use "mixins";
@use "variables" as *;

@include mixins.font-face(CeraPRO, fonts/CeraPROThin, 200, normal, ttf);
@include mixins.font-face(CeraPRO, fonts/CeraPROThinItalic, 200, italic, ttf);
@include mixins.font-face(CeraPRO, fonts/CeraPROLight, 300, normal, ttf);
@include mixins.font-face(CeraPRO, fonts/CeraPROLightItalic, 300, italic, ttf);
@include mixins.font-face(CeraPRO, fonts/CeraPRORegular, normal, normal, ttf);
@include mixins.font-face(
    CeraPRO,
    fonts/CeraPRORegularItalic,
    normal,
    italic,
    ttf
);
@include mixins.font-face(CeraPRO, fonts/CeraPROMedium, 500, normal, ttf);
@include mixins.font-face(CeraPRO, fonts/CeraPROMediumItalic, 500, italic, ttf);
@include mixins.font-face(CeraPRO, fonts/CeraPROBold, bold, normal, ttf);
@include mixins.font-face(CeraPRO, fonts/CeraPROBoldItalic, bold, italic, ttf);

body {
  background: url("../assets/images/PMBackground.svg") no-repeat;
  background-position-x: center;
  background-position-y: top;
  background-size: cover;
  background-attachment: fixed;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "CeraPRO", sans-serif;
}

h3 {
  font-size: 20px;
  font-weight: bold;
  color: $dark-blue;
  margin-bottom: 0;
}
